.App {
  text-align: center;
}
body{
  overflow-x: hidden;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.content-margin {
  margin-top: 3vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.button {
  height: 40px;
  width: auto;
  border-radius: 50px !important;
  background-color: rgb(109, 29, 142) !important;
  border: 2px solid rgb(109, 29, 142) !important;
  color: white;
  text-wrap: nowrap;
}

.t-button {
  height: 40px;
  width: auto;
  margin: 0px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  
  background-color: rgb(98, 14, 138) !important;
  border: 2px solid rgb(98, 14, 138) !important;
  color: white;
  text-wrap: nowrap;
  position: fixed;
  top: 70px;
  z-index: 2;
}
sticky-btn{
  top: 50px !important;
}



.button:hover {
  background-color: #ffffff !important;
  border: 2px solid rgb(109, 29, 142) !important;
  color: rgb(109, 29, 142) !important;
}

.button-clr {
  height: 40px;
  width: auto;
  border-radius: 50px !important;
  border: 2px solid !important;
}

.button-clr:hover {
  border: 2px solid rgb(109, 29, 142) !important;

}

.head {
  color: rgb(109, 29, 142) !important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.main-grid {
  display: grid;
  grid-template-columns: max-content 1fr;
}

@media(max-width:767px) {
  .main-grid {
    display: block !important;
  }
}