
.card-header11 {
    background-color: #821393;
    color: white;
}
.image-container{
    aspect-ratio: 1.3/1; 
    display: flex;
    align-items: center;
    justify-content: center;
}