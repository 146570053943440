.form-card-parent {
    border: 1px solid #671b8a6b;
    border-radius: 5px;
    padding-bottom: 3rem;
}

.file-upload {
    width: 100%;
}

.img-container{
    height:auto;
}

.form-check-input:checked {
    background-color: #9034C2;
    /* Change this to your desired color */
    border-color: #b2b4b5;
}






.btn-common {
    background-color: #661B8A;
    color: white;
}

.btn-common:hover {
    background-color: #a578b9;
    color: white;
}

@media(max-width:995px){
    .img-container{
        width: 30% !important;
    }
}