
.batch-list {
    margin-top: 1rem;

}

/* .btn{
    background-color: #661B8A;
    color: white;
} */
/* .btn:hover{
background-color: #C7B6CF;
} */

.batch_card_1{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.search-bar-main {
    width:80%;
    border-radius: 10px;


}
.search-bar-child {
    width: 100%;
    background-color: #EBEBEB;
    border-radius: 10px;


}

.search-bar-input {
    height: 100%;
    width: 95%;
    background-color: #EBEBEB;
    border: none;
    border-radius: 10px;


}

.location {
    background-color: #f0e4f8;
    width: 40%;
    border-radius: 10px;
    padding: 0px;
    border: none;
}

.location:hover {
    background-color: #C7B6CF;
    border: 1px solid #661B8A;

}

.t-btn {
   
   position: absolute;
   
    
}
.toggle-btn {
    background-color: white;
    
}

.batch-card{
    background-color: white;
   
}
.toggle-btn:hover {
    background-color: white;
    
}

.crd-header{
    border-bottom: 2px solid rgba(131, 92, 150, 0.359);
    border-radius: 8px;
}



.status_clr{
    color: rgb(51, 175, 20);
}  