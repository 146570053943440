.info-bar {
  display: flex;
  /* margin-top: 0.5rem; */
  height: 2rem;
}


.info-item {
  padding: 0.3rem;
  margin-right: 1rem;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.info-gap {
  margin-right: 1rem;
}




.checkbox-green input[type=checkbox] {
  border: 1px solid #198754;
  border-radius: 3px;
  height: 30px;
  width: 30px;
}

.checkbox-green input[type=checkbox]:checked {
  background-color: #198754;
}

.checkbox-blue input[type=checkbox] {
  border: 1px solid #0d6efd;
  height: 30px;
  width: 30px;
}

.checkbox-blue input[type=checkbox]:checked {
  background-color: #0d6efd;
}

.checkbox-red input[type=checkbox] {
  border: 1px solid #dc3545;
  height: 30px;
  width: 30px;
}

.checkbox-red input[type=checkbox]:checked {
  background-color: #dc3545;
}

.checkbox-yellow input[type=checkbox] {
  border: 1px solid #ffc107;
  height: 30px;
  width: 30px;
}

.checkbox-yellow input[type=checkbox]:checked {
  background-color: #ffc107;
}

.calendar-container {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 50;
  transition: visibility 0s, opacity 0.5s linear;
}

.hovered {
  cursor: pointer;
}

.calendar-container2 {
  position: absolute;
  z-index: 999;
  visibility: hidden;
  opacity: 50%;
  transition: visibility 0s, opacity 0.5s linear;
}

.calendar-container2.visible {
  visibility: visible;
  opacity: 1;
}

.calendar-container.visible {
  visibility: visible;
  opacity: 1;
}

.max-30 {
  max-width: 30vw;
}

.demo input[type=checkbox] {
  height: 15px;
  width: 15px;
}

.demo {
  margin: 0;
}
.table-wrapper {
  box-sizing: border-box;
  width: 90%;
  margin: 5%;
}

.form-check-input{
  background-image: none;
}

@media (max-width:576px) {

  .checkbox-green input[type=checkbox],
  .checkbox-blue input[type=checkbox],
  .checkbox-red input[type=checkbox],
  .checkbox-yellow input[type=checkbox]  {
    width: 20px;
    height: 20px;
  }

  table {
    font-size: 0.8em;
  }

  th {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  td {
    padding-left: 2px !important;
    padding-right: 2px !important;

  }
  .demo input[type=checkbox] {
    height: 15px;
    width: 15px;
  }
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 10px;
}

.custom-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.custom-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.custom-table tbody tr:hover {
  background-color: #f5f5f5;
}

.custom-table .present {
  font-weight: bold;
  color: green;
}

.custom-table .absent {
  font-weight: bold;
  color: red;
}

.custom-table .recorded {
  font-weight: bold;
  color: rgb(191, 191, 4);
}
.custom-table .online {
  font-weight: bold;
  color: blue;
}

.table-header {
  background-color: #e3e3e3;
  font-weight: bold;
}
