/* side nav */
.side-nav {
    top: 5rem;
    height: 100vh;
    padding-top: 1rem;
    width: 16%;
    max-height: auto;
    background-color: #C7B6CF;
    position: sticky;
    top:0
}
.side-bar-bg{
    background-color: #f0f0f0;
}
.side-bar{
    padding: 0;
    overflow-x: hidden;
}

.side-btn {
    background-color: #a578b9;
    border-radius: 10px;
}

.side-btn:hover {
    background-color: #661B8A;
    border-radius: 10px;
    color: white;
}

.side-btn.active {
    background-color: #661B8A;
    color: white;
}

.active-link {
    background-color: #661B8A;
    color: white;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .side-nav {
        width: 100%;
        position: static;
    }

    .side-btn,
    .side-btn.active,
    .active-link {
        border-radius: 0; /* Remove border-radius on smaller screens */
    }
}
.text-normal{
    text-decoration: none;
    color: inherit;
}
.h100,.h-md-100{
    height:100vh;
}
.offcanvas-md{
    max-width: 100vw !important;
    /* overflow-x: hidden; */   /* ruins sticky top??*/
}
.head-div{
    padding-top: 5px !important;
}
@media(max-width: 768px) {
    .h-md-100{
        height: fit-content;
    }
}