* {
    font-family: sans-serif;
}

.main-colour {
    color: #661B8A;
}


.side-img {
    height: 100vh;
    width: 100%;
}

.login-container {
    height: 100vh;
}

.form-container {
    height: 80vh;
    margin-top: 8vh;
}

.logo {
    height: 18vh;   
}

.ttile {
    color: #000;
    font-family: sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: bolder;
    line-height: normal;
}
.otpbox{
    width: 45px;
    height: 52px;
    flex-shrink: 0;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: #D9D9D9;
    padding: 10px;
}
.inputbox {
    width: 349px;
    height: 52px;
    flex-shrink: 0;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: #D9D9D9;
    padding: 10px;
}

.data-container {
    display: flex;
    justify-content: center;
    margin-right: 17%;
}

.login-btn {
    background-color: #661B8A;
    color: white;
    width: 200px;
    border-radius: 10px;
}

.login-btn:hover {
    background-color: white;
    border: 2px #661B8A solid;
    color: #661B8A;
}

.forgot-link {
    text-decoration: none;
    color: #a1a0a0;
}


@media(max-width:750px) {

    .data-container {
        display: flex;
        margin-left: 17%;
        /* justify-content: center; */
        
     
    }
    .title-container-logo{
        display: flex;
        justify-content: center !important;
       
    }
    .title-container{
        display: flex;
        justify-content: center;
        
    }
    .hide{
        display: none;
    }
    }

    @media(max-width:454px) {
        .title-container{
            display: flex;
            justify-content: center;
            
            
        }
        .title-container h1{
            font-size: x-large;
        }
         .title-container-logo .logo{

height: 12vh;

         }
       

    }
