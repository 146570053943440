
.user-dashboard{
    margin-top: 1vh;
}
.users{
    border-radius: 10px;
    color: rgb(109,29,142);
}
.clickable-card_{
    margin-top: 2%;
    cursor: pointer;
    padding: .5%;
    border-bottom: rgb(109,29,142) 2px solid;
    border-radius: 10px;
    color: rgb(109,29,142);
}
.selected-card {
    margin-top: 2%;
    padding: .5%;
    cursor: pointer;
    border-radius: 10px;
    background-color:rgb(109,29,142) !important;
    color: white !important;
    border: 0 !important;
  }
.icons{
    color: rgb(84, 171, 90);
    cursor: pointer;
} 
.icon {
    color: rgb(229, 67, 42);
    cursor: pointer;
}
.propic{
  
    width: 100%;
    border-radius: 50% !important;
}
.name-img{
    padding: 3%;
    height: auto ;
    background-color: #671b8a2f;
    border-radius: 5px;
}
::placeholder{
    color: rgba(0, 0, 0, 0.894);
    
}

.table-wrapper{
    box-sizing: border-box;
    width: 90%;
    margin: 5%;
}
/* user profile page  */

.profile-pic{
    height: 13rem;
    width: 13rem;
}
.user-dashboard-profile{
    border:1px solid #661B8A;
    border-radius: 10px;
}
.logs{
    background-color: rgb(221, 51, 51) !important;
}


#page-btn{
    width: 20px;
    margin-right: 10px;
    border: none;
}


  @media (max-width:570px){
    .users{
        width: auto;
    }

  }