.batch-card{
    margin-top: 3% !important;
    border: 2px solid rgb(109,29,142);
    border-radius: 20px;
}
.video-row{
   
    background-color: #ffffff;
    border: 2px solid rgb(109,29,142);
   
}
.scrollable-content {
    max-height: 6rem; 
    overflow: auto;
  }
.video-toggle{
    background-color: #ffffff00!important;
}  

.video-parent-container{
    margin: 1%;
    padding: 1%;

}
.live-class{
    border: 1px solid #8f8b90;
    border-radius: 10px;
    
}

.overview-head{
    padding: 1.5%;
    border-radius: 17px 17px 0px 0px;
    background-color: rgb(109,29,142);
    color: white;
    margin: 0.001%;
}
.boarder-left{
    border-left: 5px solid rgb(109,29,142) ;
  }
  .custom-border{
    border-bottom: 2px solid rgb(109,29,142);
    border-radius: 8px;
   margin: 1%;
  }
  .card-3dot{
    color: rgb(104, 99, 99) !important;
    position: absolute;
    background-color:transparent !important;
}
.table{
    
}