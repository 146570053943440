/* navbar */
* {
    font-family: sans-serif;
}

.nav-logo {
    width: 60px;
}

.nav-size {
    width: 100%;
    z-index: 1;
}
.navbar{
    width: 100%;
}

.clr {
    color: #661B8A;
}

.navbar-clr {
    /* background-color: #C7B6CF; */
    color:#f0f0f0;

}

.name-btn,
.logout-btn {
    margin-top: 1vh;
    border-radius: 10px;
    font-weight: bold;
}

.name-btn {
    background-color: #a578b9;
}

.logout-btn {
    background-color: #661B8A;
    color: white;
}

.avatar{
    width:35px;
    height: 35px;
    font-size: 25px;
    font-weight: bold;
    color: white;
    border-radius: 50%;
    background-color:#a578b9;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.nav-heading{
    white-space: nowrap; 
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; 
}
.nav-grid{
    display: grid;
    grid-template-columns:50px 1fr 80px;
    align-items: center;
}
.nav-dropdown{
   transform: translateX(-20px);
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .nav-size {
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .name-btn,
    .logout-btn {
        margin-top: 0.5vh; /* Adjust margin for smaller screens */
    }
}
@media(min-width:768px){
    .nav-grid{
        grid-template-columns: 1fr 80px;
    }
  }
