
.course-list{
    margin-top: 1vh;
  
}

.hovered {
    cursor: pointer;
}

.card-menu{
    background-color: white;
    
}
.card-menu:hover{
    background-color: white;
}
.custom-dropdown-toggle::after {
    display: none !important;
  }

.card-3dot{
    color: rgb(104, 99, 99) !important;
    position: absolute;
    background-color:transparent !important;
}

.custom-card{
    height: 300px;
    
}
/* .custom-card:hover{
    transform: scale(1.05);
    transition: ease-out 0.5s;
} */
.crd-header{
    height: 45px;
    background-color: transparent;
    border-bottom: 2px solid rgb(102,27,138);
    border-radius: 8px;
}
.crd-img{
    height:70px;
    width: 100%;
    padding-left: 20%;
    object-fit: contain;
}
.crd-head{
    height: 40px;
}

 .custom-dropdown-item:hover{
    background-color:rgb(199,182,207)!important;
    color: rgb(0, 0, 0) !important;   
  }

  @media (max-width:1430px){
    .custom-card{
        height: 300px;
    }
   
  }

  @media (max-width:1290px){
    .custom-card{
        height: 320px;
    }
    .crd-img{
        padding-top: 5%;
        padding-left: 10%;
    }

}
@media(max-width:995px){
   .custom-card{
       height:380px;
   }
   .clr{
    padding-left: 20px ;
   }
   .crd-img{
    height: 80px;
    padding-right: 20%;
    padding-left: 20%;
}

}

@media(max-width:785px){
   .custom-card{
       height:365px;
   }
   .crd-img{
    width: 80%;
    height: 150px;
    padding-right: 20%;
    padding-left: 20%;
   }

}


 @media(max-width:840px){
    .custom-card{

        height:450px;
    }
 }

 @media(max-width:775px){
    .custom-card{
        height:495px;
    }
 }

 @media(max-width:564px){
    .custom-card{
        height:430px;
    }
 }
 @media(max-width:400px){
    .custom-card{
        height:450px;
    }
 }





















/* 
.option-child {
    background-color: #C7B6CF;
    border-radius: 10px;
}

.options {
    background-color: #4b1d61;
    border-radius: 10px;
}

.options:hover {
    background-color: #a578b9;

}
.clickable-card {
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    
}

.clickable-card:hover {
    transform: scale(1.05);
}

.course-card {
    margin-top: 3vh;
    display: flex;
    margin-bottom: 9vh;
    justify-content: center;
}


.card {
    background-color: #C7B6CF;
    border: 1px solid #661B8A;
    border-radius: 10px;
    height: 100%;
    width: 90%;
}

.crd-img {
    width: 100%;
}

.add-course {
    background-color: #661B8A;
    color: white;
    font-weight: bold;

}


.container {
    margin-top: 20px;
}


.custom-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.card-title {
    font-size: 1.2rem;
    color: #661B8A;
}

.nav-item:hover {
    background-color: #a578b9;
}


.option-child:hover {
    background-color: #a578b9;
}


.btn {
    font-weight: bold;
}


.btn:hover {
    background-color: #661B8A;
}
 */
