.selected-faculty-container {
    width: 50%;
    padding: 10px;
}
.border_{

        border: 1px double #ccc;

}
.select-name{
    border: 1px solid #ccc;
}
