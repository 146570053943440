.search-bar-main {
    width: 80%;
    border-radius: 10px;


}

.search-bar-child {
    width: 100%;
    background-color: #EBEBEB;
    border-radius: 10px;


}

.search-bar-input {
    height: 100%;
    width: 95%;
    background-color: #EBEBEB;
    border: none;
    border-radius: 10px;


}

.user-dashboard {
    margin-top: 3vh;
    /* margin-left: 3vh; */
}

.users {
    border-radius: 10px;
    border: 2px solid #661B8A;
}

.clickable-card {
    cursor: pointer;
}

.selected-card {
    cursor: pointer;
    background-color: #C7B6CF;

}

.icons {
    color: rgb(80, 166, 89);
    cursor: pointer;
}

.icons2 {
    color: rgb(218, 5, 5);
    cursor: pointer;
}

.icons3 {
    color: rgb(232, 225, 7);
    cursor: pointer;
}


.pagination-container {
    text-align: center;
    margin-top: 20px;
}

.pagination-button {
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
}

#page-btn {
    width: 20px;
    margin-right: 10px;
    border: none;
}

.next {
    background-color: #661B8A;
    color: #fff;
    border: none;
}

.prev {
    background-color: #661B8A;
    color: #fff;
    border: none;
}

.table-wrapper {
    box-sizing: border-box;
    width: 90%;
    margin: 5%;
}


.custom-dialog .modal-body,.custom-dialog .modal-header,.custom-dialog .modal-footer{
    background-color: black;
    color:white;
    border:none;
}
.profile-pic{
    height:200px;
    border-radius: 50% !important;
}