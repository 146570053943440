

  .img{
    object-fit:cover;
  }
  .course-head{
    border-radius: 20px 20px 0px 0px;
    background-color: rgb(109,29,142);
    color: white;
  }
  .course-body{
    margin: 1%;
    border-radius: 23px 23px 23px 23px;
    border: 2px solid rgb(109,29,142) !important;
  }
  .custom-border{
    border-bottom: 2px solid rgb(109,29,142);
    border-radius: 8px;
   margin: 1%;
  }
  .text{
    text-align: justify;
  }
  .thumbnail{
    object-fit:fill;
    /* margin-top: 10%;
    margin-bottom: 10%; */
  }
  .boarder-left{
    border-left: 5px solid rgb(109,29,142) ;
  }
  

  @media(max-width:1430px){
    .thumbnail{
      padding-top: 20% !important;
      padding-bottom: 20% !important;
    }
    
  }

  @media(max-width:1150px){
    .thumbnail{
      padding-top: 30% !important;
      padding-bottom: 30% !important;
      /* margin-left: 28px; */
    }
    
  }

  @media(max-width:1080px){
    .thumbnail{
      padding-top: 30% !important;
      padding-bottom: 30% !important;
      /* margin-left: 28px; */
    }
    
  }

  @media(max-width:995px){
    .thumbnail{
      padding: 0% !important;
      width: 20% !important;
      margin-top: 0% !important;
      margin-bottom: 0% !important;
      
    }
    .course-details{
      height: 60px !important;
    }

   
  }

  @media(max-width:915px){
    .thumbnail{
      margin-top: 0% !important;
      margin-bottom:0% !important;

    }
    .course-head_{
      margin-top: 0% !important;
      text-align: justify;
    }
    
  }
  @media(max-width:880px){
    .mod_description{
      margin: 0 !important;
    }
    .thumbnail{
      /* margin-top: 20% !important;
      margin-bottom: 20% !important; */

    }
  }

  @media (max-width: 767px) {
    .thumbnail { 
        width: 20% !important;
        margin-top: 1% !important;
        margin-bottom: 1% !important;

    }
    .course-head_{
      margin-top: 0% !important;
      text-align: justify;
    }
  }