/* .loader-container{
    position: fixed;
    top: 50%;
    left:50%;
    
    
    
}
.inner-round{
    color:#661B8A;
    size: 100px;
  
} */

.custom-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.custom-spinner {
    border: 8px solid #f3f3f3;
    /* Light grey */
    border-top: 8px solid #661B8A;
    /* Blue */
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}