.video-screen{
    height: 13rem;
    width: 100%;
}

.demo-video-content{
    max-height: 6rem;
    min-height: 6rem;
}


.thumbnail_{
    width: 80% !important;
    /* padding: 2%; */
}


.demo-video-head{
    margin: .02%;
    border-radius: 20px 20px 0px 0px;
    background-color: rgb(109,29,142);
    color: white;
  }
  .custom-border{
    margin: 0 !important;
    border-bottom: 2px solid rgb(109,29,142);
    border-radius: 8px;
  }
  .demo-body{ 
    border-radius: 23px 23px 23px 23px;
    border: 2px solid rgb(109,29,142) !important;
  }
  .demo-description{
    text-align: justify;
  }

  @media (max-width:1180px){
    .thumbnail_{
        padding-top: 20% !important;
        padding-bottom: 20% !important;
    }
  } 
  @media (max-width:940px){
   .full-name{
    font-size: 200%;
   }
   .thumbnail_{
   padding-top: 30% !important;
    padding-bottom: 30% !important;
   }
  }

  @media (max-width:760px){
    .thumbnail_{
        width: 30% !important;
        padding-top: 1% !important;
        padding-bottom: 1% !important;
    }
    .full-name{
        padding-top: 1% !important;
        font-size: 180%;
        text-align: justify;
    }
  } 
